import { useState, useRef } from "react";
import Popper from "@mui/material/Popper";
import ColorPicker from "react-pick-color";
import { ClickAwayListener, Grow, MenuList } from "@mui/material";
const CLColorPicker = ({ row, onColorChange }) => {
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [selectedColor, setSelectedColor] = useState(
    row?.color_code === null ? "#ffffff" : row?.color_code
  );
  const handleToggle = () => setOpen((prevOpen) => !prevOpen);
  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const handleColorChange = (color) => {
    setSelectedColor(color.hex);
    onColorChange(row.guid, color.hex);
  };

  return (
    <div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          border: "1px solid #E5E8E8",
          backgroundColor: "white",
          borderRadius: "6px",
          width: "168px",
          height: "32px",
          padding: "5px",
          gap: "5px",
        }}
        onClick={handleToggle}
        ref={anchorRef}
      >
        <div
          style={{
            width: "18px",
            height: "18px",
            borderRadius: "2px",
            backgroundColor: selectedColor,
            border: "1px solid #E5E8E8",
          }}
        ></div>
        <span className="hex-color-title-text">{selectedColor}</span>
      </div>
      <Popper
        transition
        open={open}
        disablePortal
        role={undefined}
        placement="left-start"
        anchorEl={anchorRef.current}
        modifiers={[
          {
            name: "arrow",
            options: {
              element: "[data-popper-arrow]",
            },
          },
        ]}
        style={{ zIndex: "9999999 !important" }}
      >
        {({ TransitionProps }) => (
          <Grow {...TransitionProps} style={{ transformOrigin: "center top" }}>
            <div className="color-picker-popper" style={{ marginRight: "5px" }}>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList>
                  <ColorPicker
                    className="color-picker-summary"
                    hideAlpha
                    color={selectedColor}
                    onChange={handleColorChange} 
                  />
                  <span className="popper-arrow" data-popper-arrow></span>
                </MenuList>
              </ClickAwayListener>
            </div>
          </Grow>
        )}
      </Popper>
    </div>
  );
};

export default CLColorPicker;
