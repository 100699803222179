import { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Header from "../../Components/Header/Header";
import { motion } from "framer-motion";
import { Divider, FormControl, Grid, Menu, MenuItem, Switch, TextField, Typography } from "@mui/material";
import { BorderLinearProgress } from "../../Common/MuiStyles/MuiStyles";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import {
  categoryPercentCalculate,
  filterObjectsUptoCategory,
  filterObjectsUptoTemplate,
  getNonNarrativeTemplatesGuid,
} from "../../Helpers/HelperFunctions";
import "../../../Assets/Styles/Containers/_inspection.scss";
import AddItemButton from "../../Components/Button/AddItemButton";
import { useDispatch, useSelector } from "react-redux";
import AddCategoryModal from "../../Components/Category/AddCategoryModal";
import CreateNewCategoryModal from "../../Components/Category/CreateNewCategoryModal";
import {
  getDefaultCategories,
  UpdateTemplateDataSpecific,
} from "../../Helpers/ApiCall";
import EditIcon from "@mui/icons-material/Edit";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import { Box, Modal } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import "../../../Assets/Styles/Containers/_modal.scss";
import { v4 as uuidv4 } from "uuid";
import FilledButton from "../../Components/Button/FilledButton";
import { GetUTCTime } from "../../Helpers/GetUTCTime";
import Preloader from "../../Helpers/Preloader";
import { DragDropContext, Droppable, Draggable } from "@hello-pangea/dnd";
import ReorderIcon from "@mui/icons-material/Reorder";
import toast from "react-hot-toast";
import DeleteConfirmationDialog from "../../Common/Alert/DeleteConfirmationDialog";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { MdOutlineModeEditOutline } from "react-icons/md";
import { duplicateIcon, moveOrCopyCategory } from "../../../Assets/Svg/Svg";
import { RiDeleteBin6Line } from "react-icons/ri";
import AssignColorModal from "./AssignColorIndicatorModal";
import AssignColorIndicatorModal from "./AssignColorIndicatorModal";
import { IoMdColorPalette } from "react-icons/io";
import DoneIcon from '@mui/icons-material/Done';
import { AiOutlineCopy } from "react-icons/ai";
const Inspection = () => {
  const [mainStoreData, setMainStoreData] = useState();
  const [inspectionResponse, setInspectionResponse] = useState();
  const [loader, setLoader] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  // redux
  const dispatch = useDispatch();
  const storeData = useSelector((state) => state.CommonReducer);
  const assignColorList = storeData?.inspectionData?.data?.inspectorFlagList?.filter((item) => item.is_deleted !== 1) || [];

  useEffect(() => {
    setMainStoreData(storeData);
  }, [storeData]);
  let templateId = location?.state?.template_id;
  let templateData = inspectionResponse?.inspectiontemplates?.find(
    (template) => template.template_id === templateId
  );
  const [showCategoryModal, setShowCategoryModal] = useState(false);
  const [editCategory, setEditCategory] = useState(false);
  const [deleteCategory, setDeleteCategory] = useState(false);
  const [reorderCategory, setReorderCategory] = useState(false);
  const [showCreateCategoryModal, setShowCreateCategoryModal] = useState(false);
  const [activeDeleteCategory, setActiveDeleteCategory] = useState();
  const [deleteCategoryModal, setShowDeleteModal] = useState(false);
  const [showEditCategoryModal, setShowEditCategoryModal] = useState(false);
  const [activeEditCategory, setActiveEditCategory] = useState(false);
  const [editCategoryName, setEditCategoryName] = useState("");
  const [newCategoryList, setNewCategoryList] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [categoryMenuAnchorEl, setCategoryMenuAnchorEl] = useState(null);
  const [assignColorIndicatorModal, setAssignColorIndicatorModal] = useState(false);
  const [assignColorIndicatorCategory, setAssignColorIndicatorCategory] = useState();
  // reorder
  const [catoutput, setCatOutput] = useState([]);
  const [catreorder, setCatReorder] = useState(false);

  const isCategoryMenuOpen = Boolean(categoryMenuAnchorEl);

  const handleMoreIconClick = (event, category) => {
    setAssignColorIndicatorCategory(category);
    setCategoryMenuAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setCategoryMenuAnchorEl(null);
  };

  const handleAssignColor = () => {
    setAssignColorIndicatorModal(true);
    // setCategoryMenuAnchorEl(null);
  };

  useEffect(() => {
    setCatOutput(templateData?.categories);
  }, [templateData]);
  useEffect(() => {
    if (templateData?.categories) {
      setTimeout(function () {
        setLoader(false);
      }, 2500);
    }
  }, [templateData]);

  const handleAddCategory = () => {
    setNewCategoryList([]);
    getDefaultCategories(
      dispatch,
      storeData.inspectionData?.data?.inspectiontemplates?.find(
        (template) => template.template_id === templateId
      )?.template_guid
    );
    setShowCategoryModal(true);
    setSelectedCategories([]);
  };

  const handleCategorySelection = (category) => {
    if (category.is_selected === false) category.is_selected = true;
    if (selectedCategories?.length === 0) {
      setSelectedCategories([category]);
    } else {
      setSelectedCategories((prev) => {
        let catIndex = prev.findIndex((item) => item.uuid === category.uuid);
        if (catIndex !== -1) {
          return prev.filter(
            (prevCategory) => prevCategory.uuid !== category.uuid
          );
        } else return [...prev, category];
      });
    }
  };

  const handleDeleteCategory = (category) => {
    setShowDeleteModal(true);
    setActiveDeleteCategory(category);
  };

  const handleEditCategory = (category) => {
    setShowEditCategoryModal(true);
    setActiveEditCategory(category);
    setEditCategoryName(category.prompt);
  };

  const handleCloseDeleteAlert = () => {
    setShowDeleteModal(false);
  };
  const handleCloseEditAlert = () => {
    setShowEditCategoryModal(false);
  };

  const handleConfirmDeleteCategory = async () => {
    let templateIndex = inspectionResponse?.inspectiontemplates?.findIndex(
      (itm) => itm.template_id === templateId
    );
    let categoryIndex = inspectionResponse?.inspectiontemplates[
      templateIndex
    ]?.categories?.findIndex((itm) => itm.uuid === activeDeleteCategory.uuid);
    let prevData = JSON.parse(JSON.stringify(inspectionResponse));
    delete prevData?.report_media_library_files;
    prevData.inspectiontemplates[templateIndex].categories[
      categoryIndex
    ].is_deleted = 1;
    prevData.inspectiontemplates[templateIndex].categories[
      categoryIndex
    ].updated_at = await GetUTCTime();

    let filteredObject = filterObjectsUptoCategory(
      prevData,
      templateIndex,
      categoryIndex
    );
    setDeleteCategory(true);
    UpdateTemplateDataSpecific(dispatch, prevData, filteredObject);
    setShowDeleteModal(false);
  };

  const handleConfirmEditCategory = async (editedName) => {
    let templateIndex = inspectionResponse?.inspectiontemplates?.findIndex(
      (itm) => itm.template_id === templateId
    );
    let categoryIndex = inspectionResponse?.inspectiontemplates[
      templateIndex
    ]?.categories?.findIndex((itm) => itm.uuid === activeEditCategory.uuid);
    let prevData = JSON.parse(JSON.stringify(inspectionResponse));
    delete prevData?.report_media_library_files;
    prevData.inspectiontemplates[templateIndex].categories[
      categoryIndex
    ].prompt = editedName;
    prevData.inspectiontemplates[templateIndex].categories[
      categoryIndex
    ].updated_at = await GetUTCTime();

    setEditCategory(true);
    let filteredObject = filterObjectsUptoCategory(
      prevData,
      templateIndex,
      categoryIndex
    );
    UpdateTemplateDataSpecific(dispatch, prevData, filteredObject);
    setShowEditCategoryModal(false);
  };

  const assosiatedCategoryColor = (inspector_flagging_guids, assignColorList) => {
    if (!inspector_flagging_guids || inspector_flagging_guids?.length === 0) {
      return [];
    }
    return inspector_flagging_guids.map((guid) => {
      const matchedColor = assignColorList.find((item) => item.guid === guid);
      return matchedColor ? matchedColor.color_code : "#FFFFFF";
    });
  };


  useEffect(() => {
    if (storeData?.loader) setLoader(true);
    else {
      setLoader(false);
    }
  }, [storeData?.loader]);

  useEffect(() => {
    if (storeData?.loader) setLoader(true);
    else {
      setLoader(false);
    }
  }, [storeData?.loader]);

  useEffect(() => {
    if (storeData.inspectionData) {
      setMainStoreData(storeData);
    }
  }, [storeData.editCategory]);

  useEffect(() => {
    if (storeData.inspectionData) {
      setInspectionResponse(storeData.inspectionData?.data);
    }
  }, [storeData.inspectionData]);

  // optional and standard categories
  useEffect(() => {
    if (storeData.defaultCategoryList) {
      setNewCategoryList(
        storeData.defaultCategoryList?.length !== 0
          ? storeData.defaultCategoryList?.data
          : []
      );
    }
  }, [storeData.defaultCategoryList]);

  // reorder
  const reorder = (data, startIndex, endIndex) => {
    const result = Array.from(data);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const onDragEnd = async (result, templateId) => {
    if (!result.destination) {
      return;
    }
    setLoader(true);

    const reorderedItems = reorder(
      catoutput,
      result.source.index,
      result.destination.index
    );

    reorderedItems.forEach((x, i) => {
      x.order = i + 1;
      return x;
    });
    setCatOutput(reorderedItems);
    let prevData = JSON.parse(JSON.stringify(inspectionResponse));
    delete prevData?.report_media_library_files;
    reorderedItems.map(async (item) => {
      item.updated_at = await GetUTCTime();
    });
    const index = prevData.inspectiontemplates.findIndex((item) => {
      return item.template_id == templateId;
    });
    prevData.inspectiontemplates[index].categories = reorderedItems;
    prevData.inspectiontemplates[index].updated_at = await GetUTCTime();
    setReorderCategory(true);
    let filteredObject = filterObjectsUptoTemplate(prevData, index);
    UpdateTemplateDataSpecific(dispatch, prevData, filteredObject);
  };

  const handleCategoryNavigate = (category, assosiatedCategoryColors) => {
    navigate(
      `/inspection/${category.prompt !== null
        ? encodeURIComponent(category.prompt)
        : "Untitled"
      }`,
      {
        state: {
          category,
          category_name: category.prompt,
          template_id: templateData.template_id,
          category_id: category.id,
          template_guid: templateData.template_guid,
          isNarrative: location?.state?.isNarrative || false,
          assosiatedCategoryColors: assosiatedCategoryColors,
        },
      }
    )
  };


  useEffect(() => {
    if (storeData?.updatedInspectionData?.success === true && editCategory) {
      toast.success("Category name has been updated.");
      setEditCategory(false);
    }
    if (storeData?.updatedInspectionData?.success === true && deleteCategory) {
      toast.success("Category has been deleted.");
      setDeleteCategory(false);
    }
    if (storeData?.updatedInspectionData?.success === true && reorderCategory) {
      toast.success("The inspection has been updated successfully.");
      setReorderCategory(false);
    }
  }, [handleConfirmDeleteCategory, handleConfirmEditCategory, onDragEnd]);

  const menuItemForCategoryAndLines = () => {
    return (
      <Menu
        anchorEl={categoryMenuAnchorEl}
        open={isCategoryMenuOpen}
        onClose={handleMenuClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        sx={{
          "& .MuiPaper-root": {
            boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.05)",
            backgroundColor: "#ffffff",
            borderRadius: "8px",
            border: "1px solid #e0e0e0",
            padding: "8px 0",
            width: "240px",
          },
        }}
      >
        <MenuItem onClick={handleMenuClose} style={{ display: "flex", alignItems: "center", gap: "10px" }}>
          <MdOutlineModeEditOutline style={{ fontSize: "20px", color: "#555555" }} />
          <span style={{ fontWeight: "400", fontSize: "14px", color: "#333333" }}>Edit category name</span>
        </MenuItem>
        <MenuItem onClick={handleMenuClose} style={{ display: "flex", alignItems: "center", gap: "10px" }}>
          <AiOutlineCopy style={{ fontSize: "20px", color: "#555555" }} />
          <span style={{ fontWeight: "400", fontSize: "14px", color: "#333333" }}>Duplicate category</span>
        </MenuItem>
        {/* <MenuItem onClick={handleMenuClose} style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                          <AiOutlineArrowRight style={{ fontSize: "20px", color: "#555555" }} />
                          <span style={{ fontWeight: "400", fontSize: "14px", color: "#333333" }}>Move or copy category</span>
                        </MenuItem>
                        <Divider sx={{ margin: "8px 0" }} />
                        <MenuItem style={{ display: "flex", alignItems: "center", justifyContent: "space-between", padding: "8px 16px" }}>
                          <span style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                            <AiOutlineFile style={{ fontSize: "20px", color: "#555555" }} />
                            <span style={{ fontWeight: "400", fontSize: "14px", color: "#333333" }}>Print in report</span>
                          </span>
                          <Switch size="small" />
                        </MenuItem> */}
        <Divider sx={{ margin: "8px 0" }} />
        <MenuItem onClick={handleAssignColor} style={{ display: "flex", alignItems: "center", gap: "10px" }}>
          <IoMdColorPalette style={{ fontSize: "20px", color: "#555555" }} />
          <span style={{ fontWeight: "400", fontSize: "14px", color: "#333333" }}>Assign color</span>
        </MenuItem>
        <Divider sx={{ margin: "8px 0" }} />
        <MenuItem
          onClick={handleMenuClose}
          style={{
            display: "flex",
            alignItems: "center",
            gap: "10px",
            color: "red",
            fontWeight: "400",
            fontSize: "14px",
          }}
        >
          <RiDeleteBin6Line style={{ fontSize: "20px", color: "red" }} />
          <span>Delete category</span>
        </MenuItem>
      </Menu>
    )
  }

  return (
    <motion.div
      className="h_100 w_bg"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
    >
      {storeData.scrolPage ? window.scrollTo(0, 0) : ""}
      <Preloader showPreloader={loader} />
      <AddCategoryModal
        setLoader={setLoader}
        newCategoryList={newCategoryList}
        templateData={templateData}
        showCategoryModal={showCategoryModal}
        setShowCategoryModal={setShowCategoryModal}
        showCreateCategoryModal={showCreateCategoryModal}
        setShowCreateCategoryModal={setShowCreateCategoryModal}
        handleCategorySelection={handleCategorySelection}
        selectedCategories={selectedCategories}
        setSelectedCategories={setSelectedCategories}
      />
      <CreateNewCategoryModal
        newCategoryList={newCategoryList}
        setNewCategoryList={setNewCategoryList}
        templateData={templateData}
        showCreateCategoryModal={showCreateCategoryModal}
        setShowCreateCategoryModal={setShowCreateCategoryModal}
        showCategoryModal={showCategoryModal}
        setShowCategoryModal={setShowCategoryModal}
        handleCategorySelection={handleCategorySelection}
      />
      <Header title="Inspection" action="categories" />
      {/* reorder */}
      <Box className="inspectionBox">
        <Typography className="inspection__title">
          {templateData?.name}
        </Typography>
        {mainStoreData?.reorderCategory ? (
          <DragDropContext onDragEnd={(data) => onDragEnd(data, templateId)}>
            <Droppable droppableId={String(Math.floor(Math.random() * 100))}>
              {(provided) => (
                <div ref={provided.innerRef}>
                  {catoutput?.map((category, index) => {
                    return (
                      <>
                        {getNonNarrativeTemplatesGuid(
                          inspectionResponse?.inspectiontemplates
                        )?.[0] !== templateData?.template_guid &&
                          category.is_inspection_details === 1
                          ? ""
                          : category?.is_deleted == 0 && (
                            <Draggable
                              draggableId={String(category.uuid)}
                              // isDragDisabled ={actives && items.line_id === ids}
                              key={category.uuid}
                              index={index}
                            >
                              {(provided) => (
                                <div
                                  key={index}
                                  ref={provided.innerRef}
                                  {...provided.dragHandleProps}
                                  {...provided.draggableProps}
                                >
                                  <div
                                    className={`progress__section__category ${index === 0 ||
                                      (getNonNarrativeTemplatesGuid(
                                        inspectionResponse?.inspectiontemplates
                                      )?.[0] !==
                                        templateData?.template_guid &&
                                        index === 1)
                                      ? "border-top"
                                      : ""
                                      }`}
                                    key={category.uuid}
                                  >
                                    <div
                                      className="edit_category pointer"
                                      id={`reorder_cat_${index}`}
                                      style={{ marginRight: "15px" }}
                                    >
                                      <Typography>
                                        {category.prompt !== null
                                          ? category.prompt
                                          : "Untitled"}
                                      </Typography>
                                      <Typography className="edit_container_icons">
                                        <span>
                                          <ReorderIcon />
                                        </span>
                                      </Typography>
                                      {/* <BorderLinearProgress variant="determinate" value={0} /> */}
                                    </div>
                                  </div>
                                </div>
                              )}
                            </Draggable>
                          )}
                      </>
                    );
                  })}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        ) : (
          <>
            {" "}
            {mainStoreData?.editCategory
              ? templateData?.categories?.map((category, index) =>
                getNonNarrativeTemplatesGuid(
                  inspectionResponse?.inspectiontemplates
                )?.[0] !== templateData?.template_guid &&
                  category.is_inspection_details === 1 ? (
                  ""
                ) : category?.is_deleted !== 1 ? (
                  <div
                    className={`progress__section__category ${index === 0 ||
                      (getNonNarrativeTemplatesGuid(
                        inspectionResponse?.inspectiontemplates
                      )?.[0] !== templateData?.template_guid &&
                        index === 1)
                      ? "border-top"
                      : ""
                      }`}
                    key={category.uuid}
                  >
                    <div
                      className="edit_category pointer"
                      style={{ marginRight: "15px" }}
                    >
                      <Typography>
                        {category.prompt !== null
                          ? category.prompt
                          : "Untitled"}
                      </Typography>

                      <Typography className="edit_container_icons">
                        <span
                          onClick={() => handleEditCategory(category)}
                          id={`edit_cat_${index}`}
                        >
                          <EditIcon className="edit__icon" />
                        </span>
                        <span
                          onClick={() => handleDeleteCategory(category)}
                          id={`delete_cat_${index}`}
                        >
                          <RemoveCircleOutlineIcon className="delete__icon" />
                        </span>
                      </Typography>
                    </div>
                  </div>
                ) : (
                  ""
                )
              )
              : templateData?.is_pdf_template !== 1
                ? templateData?.categories?.map((category, index) =>
                  getNonNarrativeTemplatesGuid(
                    inspectionResponse?.inspectiontemplates
                  )?.[0] !== templateData?.template_guid &&
                    category.is_inspection_details === 1 ? (
                    ""
                  ) : category?.is_deleted !== 1 && !category?.showEmpty ? (
                    <div
                      className={`progress__section__category ${index === 0 ||
                        (getNonNarrativeTemplatesGuid(
                          inspectionResponse?.inspectiontemplates
                        )?.[0] !== templateData?.template_guid &&
                          index === 1)
                        ? "border-top"
                        : ""
                        }`}
                      key={index}
                    >
                      <div
                        className="new__relative"
                        id={`category_${index}`}
                      >
                        <Grid conatiner sx={{ width: "90%", display: "flex", alignItems: "center", zIndex: 1 }} onClick={() => handleCategoryNavigate(category, assosiatedCategoryColor(category?.inspector_flagging_guids, assignColorList))}>
                          <Grid item xs={0.5} justifyContent={"flex-end"} display={"flex"} alignItems={"center"} flexDirection={category?.inspector_flagging_guids?.length === 2 ? "column" : "row"}>
                            {categoryPercentCalculate(category)?.percentage === 100 ? (
                              <DoneIcon className="category_done_icon" />
                            ) : (
                              <>
                                {category?.inspector_flagging_guids?.length <= 2 &&
                                  assosiatedCategoryColor(category?.inspector_flagging_guids, assignColorList).map(
                                    (color, index) => (
                                      <div
                                        key={index}
                                        className="category_color_indicator"
                                        style={{
                                          backgroundColor: color,
                                          marginLeft: category?.inspector_flagging_guids?.length === 2 ? "21px" : index > 0 ? "-5px" : "0px",
                                        }}
                                      />
                                    )
                                  )}

                                {category?.inspector_flagging_guids?.length >= 3 &&
                                  assosiatedCategoryColor(category?.inspector_flagging_guids, assignColorList)
                                    .slice(0, 3)
                                    .map((color, index) => (
                                      <div
                                        key={index}
                                        style={{
                                          position: "absolute",
                                          width: "17px",
                                          height: "17px",
                                          borderRadius: "50%",
                                          backgroundColor: color,
                                          border: "2px solid #FFFFFF",
                                          top: index === 0 ? "13px" : index === 1 ? "29px" : "21px",
                                          left: index === 0 ? "20px" : index === 1 ? "21px" : "27px",
                                        }}
                                      ></div>
                                    ))}

                                {category?.inspector_flagging_guids?.length > 3 && (
                                  <div
                                    style={{
                                      position: "absolute",
                                      top: "5px",
                                      left: "40px",
                                      fontSize: "20px",
                                      fontWeight: "bold",
                                      color: "#000",
                                    }}
                                  >
                                    +
                                  </div>
                                )}
                              </>
                            )}
                          </Grid>
                          <Grid item xs={11.5}><Typography> {category.prompt !== null ? category.prompt : "Untitled"} </Typography> </Grid>
                        </Grid>
                        <Typography
                          sx={{ width: "10%", textAlign: "end" }}
                          className="category__arrow_right"
                        >
                          <KeyboardArrowRightIcon />
                        </Typography>
                        <BorderLinearProgress
                          variant="determinate"
                          value={categoryPercentCalculate(category)?.percentage}
                        />
                        <div className="category__moreicon">
                          <MoreVertIcon
                            sx={{ cursor: "pointer", color: "#9B9B9B", zIndex: 10 }}
                            onClick={(event) => handleMoreIconClick(event, category)}
                          />
                        </div>
                      </div>
                      {/* Menu */}
                      {menuItemForCategoryAndLines()}
                    </div>
                  ) : (
                    ""
                  )
                )
                : templateData?.pdf_categories?.map((pdfcategory, pdfindex) =>
                  getNonNarrativeTemplatesGuid(
                    inspectionResponse?.inspectiontemplates
                  )?.[0] !== templateData?.template_guid &&
                    pdfcategory.is_inspection_details === 1 ? (
                    ""
                  ) : pdfcategory?.is_deleted !== 1 ? (
                    <div
                      className={`progress__section__category ${pdfindex === 0 ||
                        (getNonNarrativeTemplatesGuid(
                          inspectionResponse?.inspectiontemplates
                        )?.[0] !== templateData?.template_guid &&
                          pdfindex === 1)
                        ? "border-top"
                        : ""
                        }`}
                      key={pdfindex}
                    >
                      <div
                        className={
                          pdfcategory?.type == 3
                            ? "how_to_use_this_form"
                            : "new__relative"
                        }
                        id={`category_${pdfindex}`}
                      >

                        {pdfcategory?.type != 3 ? <> <Grid item xs={0.5} justifyContent={"flex-end"} display={"flex"} alignItems={"center"} flexDirection={pdfcategory?.inspector_flagging_guids?.length === 2 ? "column" : "row"} onClick={() =>
                          navigate(
                            `/inspection/${pdfcategory.prompt !== null
                              ? encodeURIComponent(pdfcategory.prompt)
                              : "Untitled"
                            }`,
                            {
                              state: {
                                pdfcategory,
                                template_id: templateData?.template_id,
                                category_id: pdfcategory?.id,
                                isPDF: 1,
                                template_guid: templateData.template_guid,
                                pdfcategoryType: pdfcategory?.type,
                                isNarrative: location?.state?.isNarrative
                                  ? true
                                  : false,
                              },
                            }
                          )
                        }>
                          {categoryPercentCalculate(pdfcategory)?.percentage === 100 ? (
                            <DoneIcon style={{ color: "#4282E2" }} />
                          ) : (
                            <>
                              {pdfcategory?.inspector_flagging_guids?.length <= 2 &&
                                assosiatedCategoryColor(pdfcategory?.inspector_flagging_guids, assignColorList).map(
                                  (color, index) => (
                                    <div
                                      key={index}
                                      style={{
                                        width: "17px",
                                        height: "17px",
                                        borderRadius: "50%",
                                        backgroundColor: color,
                                        marginLeft:
                                          pdfcategory?.inspector_flagging_guids?.length === 2
                                            ? "21px"
                                            : index > 0
                                              ? "-5px"
                                              : "0px",
                                        border: "2px solid #FFFFFF",
                                      }}
                                    />
                                  )
                                )}

                              {pdfcategory?.inspector_flagging_guids?.length >= 3 &&
                                assosiatedCategoryColor(pdfcategory?.inspector_flagging_guids, assignColorList)
                                  .slice(0, 3)
                                  .map((color, index) => (
                                    <div
                                      key={index}
                                      style={{
                                        position: "absolute",
                                        width: "17px",
                                        height: "17px",
                                        borderRadius: "50%",
                                        backgroundColor: color,
                                        border: "2px solid #FFFFFF",
                                        top: index === 0 ? "13px" : index === 1 ? "29px" : "21px",
                                        left: index === 0 ? "20px" : index === 1 ? "21px" : "27px",
                                      }}
                                    ></div>
                                  ))}

                              {pdfcategory?.inspector_flagging_guids?.length > 3 && (
                                <div
                                  style={{
                                    position: "absolute",
                                    top: "5px",
                                    left: "40px",
                                    fontSize: "20px",
                                    fontWeight: "bold",
                                    color: "#000",
                                  }}
                                >
                                  +
                                </div>
                              )}
                            </>
                          )}
                        </Grid>
                          <Grid item xs={11.5} onClick={() =>
                            navigate(
                              `/inspection/${pdfcategory.prompt !== null
                                ? encodeURIComponent(pdfcategory.prompt)
                                : "Untitled"
                              }`,
                              {
                                state: {
                                  pdfcategory,
                                  template_id: templateData?.template_id,
                                  category_id: pdfcategory?.id,
                                  isPDF: 1,
                                  template_guid: templateData.template_guid,
                                  pdfcategoryType: pdfcategory?.type,
                                  isNarrative: location?.state?.isNarrative
                                    ? true
                                    : false,
                                },
                              }
                            )
                          }><Typography> {pdfcategory.prompt !== null ? pdfcategory.prompt : "Untitled"} </Typography> </Grid></>
                          :
                          <Typography sx={{ width: "90%" }}>
                            {pdfcategory.prompt !== null
                              ? pdfcategory.prompt
                              : "Untitled"}
                          </Typography>}
                        {pdfcategory?.type != 3 ? (
                          <Typography
                            sx={{ width: "10%", textAlign: "end" }}
                            className="category__arrow_right "
                          >
                            <KeyboardArrowRightIcon />
                          </Typography>
                        ) : (
                          <Typography
                            sx={{ width: "10%", textAlign: "start" }}
                            className="how_to_use_this_form__arrow_right"
                          >
                            <KeyboardArrowRightIcon />
                          </Typography>
                        )}
                        {pdfcategory?.type != 3 ? (
                          <BorderLinearProgress
                            variant="determinate"
                            value={0}
                          />
                        ) : (
                          <BorderLinearProgress
                            variant="determinate"
                            value={0}
                          />
                        )}
                        {pdfcategory?.type != 3 ? (
                          <div className="category__moreicon">
                            <MoreVertIcon
                              sx={{ cursor: "pointer", color: "#9B9B9B", zIndex: "10" }}
                              onClick={(event) => handleMoreIconClick(event, pdfcategory)}
                            />
                          </div>
                        ) : (
                          ""
                        )}
                        {menuItemForCategoryAndLines()}
                      </div>
                    </div>
                  ) : (
                    ""
                  )
                )}
          </>
        )}
        {!mainStoreData?.editCategory &&
          !mainStoreData?.reorderCategory &&
          templateData?.is_pdf_template !== 1 ? (
          <div className="add__category" onClick={handleAddCategory}>
            <AddItemButton text="Category" width="100%" id="add_new_category" />
          </div>
        ) : (
          <div className="add__category"></div>
        )}
      </Box>
      {/* delete alert dialog */}
      <DeleteConfirmationDialog
        open={deleteCategoryModal}
        handleClose={handleCloseDeleteAlert}
        title={"Delete category"}
        contentText={`Are you sure you want to delete the category ${activeDeleteCategory?.prompt}?`}
        cancelBtnTitle={"Cancel"}
        cancelBtnAction={handleCloseDeleteAlert}
        submitBtnTitle={"Delete"}
        submitBtnAction={handleConfirmDeleteCategory}
      />
      {/* delete dialog end */}
      {/* edit alert dialog */}
      <Modal
        open={showEditCategoryModal}
        onClose={handleCloseEditAlert}
        BackdropProps={{ style: { opacity: "60%" } }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="mark-as-complete-modal-main"
      >
        <Box
          className="mark-as-complete-modal"
          sx={{ minHeight: "201px !important", top: "40% !important" }}
        >
          <Grid container>
            <Grid item xs={12}>
              <CloseIcon
                id="close_edit_cat_modal"
                sx={{
                  float: "right",
                  cursor: "pointer",
                  color: "#A5A5A5",
                  mt: 1,
                  mr: 1,
                }}
                onClick={handleCloseEditAlert}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sx={{
                textAlign: "left",
                marginBottom: "20px",
                marginLeft: "15px",
              }}
            >
              <p className="modal-heading">Edit category name </p>
            </Grid>
            <Grid item xs={12} sm={12}>
              <FormControl
                variant="standard"
                className="forms-control"
                style={{ paddingLeft: "15px" }}
              >
                <label className="input-form-label" htmlFor="CategoryName">
                  Category Name
                </label>
                <TextField
                  id="category_name"
                  className="input-textfield"
                  size="small"
                  variant="outlined"
                  placeholder="Category name"
                  type="text"
                  value={editCategoryName}
                  onChange={(e) => setEditCategoryName(e.target.value)}
                  style={{ width: "97%" }}
                />
              </FormControl>
            </Grid>
            <Grid
              item
              xs={12}
              sx={{
                display: "flex",
                textAlign: "center",
                mt: "14px",
                mb: "30px",
                justifyContent: "center",
                gap: "20px",
              }}
            >
              <FilledButton
                width="95%"
                text="Save"
                onClick={() => handleConfirmEditCategory(editCategoryName)}
                id="confirm_edit_cat_button"
              />
            </Grid>
          </Grid>
        </Box>
      </Modal>
      {/* edit dialog end */}

      {/* assign color modal */}
      {assignColorIndicatorModal && (
        <AssignColorIndicatorModal
          assignColorIndicatorModal={assignColorIndicatorModal}
          setAssignColorIndicatorModal={setAssignColorIndicatorModal}
          handleAssignColor={handleAssignColor}
          assignColorList={assignColorList}
          templateId={templateId}
          assignColorIndicatorCategory={assignColorIndicatorCategory}
          setAssignColorIndicatorCategory={setAssignColorIndicatorCategory}
          templateData={templateData}
          setCategoryMenuAnchorEl={setCategoryMenuAnchorEl}
        />
      )}
    </motion.div>
  );
};

export default Inspection;
