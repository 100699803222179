import ACTION_TYPES from "../Constants/ActionTypes";

export const getInspectionData = data => {
  return {
    type: ACTION_TYPES.GET_INSPECTION_DATA,
    payload:data,
  };
};
export const getInspectionDataWithOutLoader = data => {
  return {
    type: ACTION_TYPES.GET_INSPECTION_DATA_WITHOUT_LOADER,
    payload:data,
  };
};
// template list
export const getTemplateListCall = data => {
  return {
    type: ACTION_TYPES.GET_TEMPLATE_LIST,
    payload:data,
  };
};

export const swapInspectionDetailsCall = (responseData) => {
  return {
    type: ACTION_TYPES.SWAP_INSPECTION_DETAILS,
    payload:responseData,
  };
};

export const getDeletedTemplateDataCall = (responseData) => {
  return {
    type: ACTION_TYPES.GET_DELETED_TEMPLATE_DATA,
    payload:responseData,
  };
};

export const updateInspectionDataCall = (responseData) => {
  return {
    type: ACTION_TYPES.UPDATE_INSPECTION_DATA,
    payload:responseData,
  };
}
export const updateInspectionMediaDataCall = (responseData) => {
  return {
    type: ACTION_TYPES.DELETE_INSPECTION_MEDIA,
    payload:responseData,
  };
}
export const updateInspectionDataCallWithLoader = (responseData) => {
  return {
    type: ACTION_TYPES.UPDATE_INSPECTION_DATA_WITH_LOADER,
    payload:responseData,
  };
}
export const updateInspectionDataCallForAddInp = (responseData) => {
  return {
    type: ACTION_TYPES.UPDATE_INSPECTION_DATA_FOR_ADD_INP,
    payload:responseData,
  };
}
 export const updateInspectionTemplateDataOffline = (payload1) => {
    let payload = {
      success: true,
      status_code: "200",
      data: payload1,
      message: "Inspection Template Data fetched successfully.",
    };
    return {
      type: ACTION_TYPES.SEND_DATA_TO_READ_SECTION_OFFLINE,
      payload,
    };
  };

  export const clearUpdatedInspectionData =()=>{
    return {
      type: ACTION_TYPES.CLEAR_UPDATED_INSPECTION_DATA,
      payload:'',
    };
  }
  export const clearTemplateUpdateData =()=>{
    return {
      type: ACTION_TYPES.CLEAR_UPDATED_TEMPLATE_DATA,
      payload:'',
    };
  }
  export const clearInspectionDataError =()=>{
    return {
      type: ACTION_TYPES.CLEAR_INSPECTION_DATA_ERROR,
      payload:'',
    };
  }
  export const uploadMediaCall = (responseData) => {
    return {
      type: ACTION_TYPES.UPLOAD_MEDIA,
      payload:responseData,
    };
  }
  export const uploadBulkMediaCall = (responseData) => {
    return {
      type: ACTION_TYPES.BULK_UPLOAD,
      payload:responseData,
    };
  }
  // attachments
  export const uploadAttachment = (responseData) => {
    return {
      type: ACTION_TYPES.UPLOAD_ATTACHMENT,
      payload:responseData,
    };
  }

  export const duplicateLine = (responseData) => {
    return {
      type: ACTION_TYPES.DUPLICATE_LINES,
      payload:responseData,
    };
  }

  export const duplicateCategory = (responseData) => {
    return {
      type: ACTION_TYPES.DUPLICATE_CATEGORYS,
      payload:responseData,
    };
  }
  export const assignInspectorFlagToCategory = (responseData) => {
    return {
      type: ACTION_TYPES.ASSIGN_INSPECTOR_FLAG_TO_CATEGORY,
      payload:responseData,
    };
  }
  export const editInspectorFlagList = (responseData) => {
    return {
      type: ACTION_TYPES.EDIT_INSPECTOR_FLAG_LIST,
      payload:responseData,
    };
  }


  export const markReportCompleteCall = (responseData) => {
    return {
      type: ACTION_TYPES.MARK_COMPLETE,
      payload:responseData,
    };
  }
  export const porchPdfGenerateCall = (responseData) => {
    return {
      type: ACTION_TYPES.PORCH_PDF_GENERATE,
      payload:responseData,
    };
  }
// ISN mark as complete
  export const markReportCompleteISNCall = (responseData) => {
    return {
      type: ACTION_TYPES.MARK_COMPLETE_ISN,
      payload:responseData,
    };
  }
  export const markReportCompleteISNSendReportCall = (responseData) => {
    return {
      type: ACTION_TYPES.MARK_COMPLETE_ISN_SEND_REPORT,
      payload:responseData,
    };
  }
// default categories
  export const getDefaultCategoriesCall = (responseData) => {
    return {
      type: ACTION_TYPES.GET_CATEGORY_LIST,
      payload:responseData,
    };
  }

// default lines
  export const getDefaultLinesCall = (responseData) => {
    return {
      type: ACTION_TYPES.GET_CATEGORY_LINE_LIST,
      payload:responseData,
    };
  }
  //api to get all default categories(before the implementation of optional and standard categories)
  export const getDefaultCategoriesDataCall = (responseData) => {
    return {
      type: ACTION_TYPES.GET_CATEGORY_LIST_DATA,
      payload:'',
    };
  }
  //api to get master templates list for ISN 
  export const getMasterTemplatesCall = (responseData) => {
    return {
      type: ACTION_TYPES.GET_MASTER_TEMPLATE_DATA,
      payload:'',
    };
  }
  //api to update templates list for ISN 
  export const updateISNTemplatesCall = (responseData) => {
    return {
      type: ACTION_TYPES.UPDATE_ISN_TEMPLATE_DATA,
      payload:responseData,
    };
  }
  //scroll to top action 
  export const scrollTop = (responseData) => {
    return {
      type: ACTION_TYPES.SCROLL_TO_TOP,
      payload:responseData,
    };
  }
  //static background to top action 
  export const staticBackground = (responseData) => {
    return {
      type: ACTION_TYPES.STATIC_BACKGROUND,
      payload:responseData,
    };
  }

  export const editCategory = (responseData) => {
    return {
      type: ACTION_TYPES.EDIT_CATEGORY,
      payload:responseData,
    };
  }
  export const editLine = (responseData) => {
    return {
      type: ACTION_TYPES.EDIT_LINE,
      payload:responseData,
    };
  }

  // REORDER
  export const reorderCategory = (responseData) => {
    return {
      type: ACTION_TYPES.REORDER_CATEGORY,
      payload:responseData,
    };
  }

  export const bulkUploadMediaLength = (responseData) => {
    return {
      type: ACTION_TYPES.BULK_UPLOAD_MEDIA_LENGTH,
      payload:responseData,
    };
  }
  export const isbulkUploading = (responseData) => {
    return {
      type: ACTION_TYPES.BULK_UPLOADING,
      payload:responseData,
    };
  }
  export const successfulBulkUploadMediaLength = (responseData) => {
    return {
      type: ACTION_TYPES.SUCCESSFUL_BULK_UPLOAD_MEDIA_LENGTH,
      payload:responseData,
    };
  }
  export const failBulkUploadMediaLength = (responseData) => {
    return {
      type: ACTION_TYPES.FAIL_BULK_UPLOAD_MEDIA_LENGTH,
      payload:responseData,
    };
  }
  export const failedBulkUploadMedia = (responseData) => {
    return {
      type: ACTION_TYPES.FAILED_BULK_UPLOAD_MEDIA,
      payload:responseData,
    };
  }
  export const reorderLines = (responseData) => {
    return {
      type: ACTION_TYPES.REORDER_LINES,
      payload:responseData,
    };
  }
// save uuid for activating line
export const activateLines = (responseData) => {
  return {
    type: ACTION_TYPES.ACTIVATE_LINE,
    payload:responseData,
  };
}
export const clearActiveLine = (responseData) => {
  return {
    type: ACTION_TYPES.CLEAR_ACTIVE_LINE,
    payload:responseData,
  };
}
// comment library list
export const getCommentLibraryListCall = (responseData) => {
  return {
    type: ACTION_TYPES.GET_COMMENT_LIBRARY_LIST,
    payload:responseData,
  };
}
// comment library list
export const addSelectedCommentsFromClCall = (responseData) => {
  return {
    type: ACTION_TYPES.ADD_COMMENT_FROM_CL_TO_REPORT,
    payload:responseData,
  };
}
// add new comment narrative
export const addNewCommentNarrativeCall = (responseData) => {
  return {
    type: ACTION_TYPES.ADD_NEW_NARRATIVE_COMMENT,
    payload:responseData,
  };
}

// cl comment status
export const updateClCommentStatusCall = (responseData) => {
  return {
    type: ACTION_TYPES.UPDATE_CL_COMMENT_STATUS,
    payload:responseData,
  };
}

// get comment library  tag list
export const getCommentLibraryTagListCall = (responseData) => {
  return {
    type: ACTION_TYPES.GET_COMMENT_LIBRARY_TAG_LIST,
    payload:responseData,
  };
}
// comment library summary tag list
export const getCommentLibrarySummaryTagListCall = (responseData) => {
  return {
    type: ACTION_TYPES.GET_COMMENT_LIBRARY_SUMMARY_TAG_LIST,
    payload:responseData,
  };
}
// update dropdown values
export const updateDropDownValuesCall = (responseData) => {
  return {
    type: ACTION_TYPES.UPDATE_DROPDOWNVALUES,
    payload:responseData,
  };
}

export const UpdateReduxLocal = (responseData) => {
  return {
    type: ACTION_TYPES.GET_INSPECTION_RESPONSE_FOR_BULK_MEDIA,
    payload:responseData,
  };
}
export const reportLibrarySideBarToggle = (responseData) => {
  return {
    type: ACTION_TYPES.TOGGLE_REPORT_SIDEBAR,
    payload:responseData,
  };
}

export const setShowCommentLibraryModal=(value)=>{
  return{
    type:ACTION_TYPES.SHOW_COMMENT_LIBRARY_MODAL,
    payload:value
  }
}