import config_settings from "./config";

export const origin = () => {
  let BASE_URL;
  let origin = window.location.origin.toLowerCase();
  if (origin.includes("https://")) origin = origin?.replace("https://", "");
  else origin = origin?.replace("http://", "");
  BASE_URL = config_settings[origin].SERVER_URL;
  // console.log('origin', origin);
  // console.log('BASE_URL', BASE_URL);
  return BASE_URL;
};
origin();

export const IRURL = () => {
  let IR_URL;
  let origin = window.location.origin.toLowerCase();
  if (origin.includes("https://")) origin = origin?.replace("https://", "");
  else origin = origin?.replace("http://", "");
  IR_URL = config_settings[origin].IR_URL;
  // console.log('origin', origin);
  // console.log('BASE_URL', BASE_URL);
  return IR_URL;
};
export const portalUrl = () => {
  let Portal_URL;
  let origin = window.location.origin.toLowerCase();
  if (origin.includes("https://")) origin = origin?.replace("https://", "");
  else origin = origin?.replace("http://", "");
  Portal_URL = config_settings[origin].PORTAL_URL;
  return Portal_URL;
};
export const portalServerUrl = () => {
  let PORTAL_SERVER_URL;
  let origin = window.location.origin.toLowerCase();
  if (origin.includes("https://")) origin = origin?.replace("https://", "");
  else origin = origin?.replace("http://", "");
  PORTAL_SERVER_URL = config_settings[origin].PORTAL_SERVER_URL;
  return PORTAL_SERVER_URL;
};

export const API_CONSTANTS = {
  GET_INSPECTION_DATAS: `initiate-web-rw`,
  GET_INSPECTION_DATAS_WITHOUT_LOADER: `initiate-web-rw`,
  UPDATE_INSPECTION_TEMPLATE_DATA: "V2/update_inspection_templates_data",
  // UPDATE_INSPECTION_TEMPLATE_DATA: "update_inspection_templates_data",previous constants
  UPLOAD_MEDIA: "upload_media_for_inspection_template",
  UPLOAD_BULK_MEDIA: "media-lib-file-upload",
  MARK_COMPLETE: "v1/change-order-status",
  MARK_COMPLETE_ISN: "mark-isn-complete",
  SEND_REPORT: "v1/send-inspection-report",
  GET_CATEGORY_LIST: "get-category-list",
  GET_TEMPLATE_LIST: "V2/get_master_templates",
  SWAP_INSPECTION_DETAILS: "swap-inspection-detail",
  UPLOAD_ATTACHMENTS: "upload_attachments",
  GET_CATEGORY_LINE_LIST: "get-category-line-list",
  GET_DEFAULT_CATEGORIES_DATA: "get_default_categories_data",
  GET_MASTER_TEMPLATES: "V2/get_master_templates",
  GET_DELETED_TEMPLATE_DATA: "get-deleted-template-data",
  SETUP_INSPECTION_TEMPLATE: "V2/setup_inspection_template",
  DUPLICATE_LINE: "duplicate-line",
  DUPLICATE_CATEGORY: "duplicate-category",
  PORCH_PDF_GENERATE: "porch-pdf-generate",
  ASSIGN_INSPECTOR_FLAG_TO_CATEGORY: "assign-inspector-flag-to-category",
  EDIT_INSPECTOR_FLAG_LIST: "inspector-flag",
  // comment library
  GET_COMMENT_LIBRARY_LIST: "get-comment-library-list",
  ADD_COMMENT_TO_REPORT: "add-comment-to-report",
  UPDATE_CL_COMMENT_DETAILS: "update-comment-details",
  ADD_CL_COMMENT: "add-cl-comment",
  GET_CL_TAG: "get-cl-tag-list",
  GET_CL_SUMMARY_TAG: "get-cl-summary-list",
  UPDATE_COMMENT_DROPDOWN: "update-comment-dropdown",
};

export const API_METHODS = {
  GET: "GET",
  POST: "POST",
  DELETE: "DELETE",
  UPDATE: "UPDATE",
  PUT: "PUT",
};
