import React, { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Typography,
} from "@mui/material";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import { MdEdit } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import EditColorIndicator from "./EditColorIndicator";
import { ApiCallForAssignInspectorToCategory } from "../../Helpers/ApiCall";
import { getInspectionData } from "../../Redux/Actions/CommonActions";
import { Base64 } from "js-base64";

const AssignColorIndicatorModal = ({
  assignColorIndicatorModal,
  setAssignColorIndicatorModal,
  handleAssignColor,
  assignColorList,
  templateId,
  assignColorIndicatorCategory,
  templateData,
  setCategoryMenuAnchorEl,
}) => {
  const dispatch = useDispatch();
  const storeData = useSelector((state) => state.CommonReducer);

  const [editColorIndicator, setEditColorIndicator] = useState(false);
  const [selectedColors, setSelectedColors] = useState([]);

  const closeModal = () => {
    setAssignColorIndicatorModal(false);
    setCategoryMenuAnchorEl(null);
  };

  const toggleColorSelection = (colorCode) =>
    setSelectedColors((prevSelected) =>
      prevSelected.includes(colorCode)
        ? prevSelected.filter((code) => code !== colorCode)
        : [...prevSelected, colorCode]
    );
  const assignColorsToCategory = async () => {
    const formData = {
      template_guid: templateData?.template_guid,
      template_id: templateId,
      category_id: assignColorIndicatorCategory?.id,
      inspection_id: storeData.inspectionData?.data?.inspection_id,
      template_type:
        templateData?.is_narrative_template === 0
          ? "1"
          : templateData?.is_narrative_template === 1
          ? "2"
          : "3",
      inspector_flagging_guids: selectedColors,
    };
    await ApiCallForAssignInspectorToCategory(dispatch, formData);
    setTimeout(() => {
      callInitiateAPI();
    }, 2000);
    closeModal();
  };

  const callInitiateAPI = async () => {
    let sessionAssociateCompanyKey = Base64.decode(
      sessionStorage.getItem("AC")
    );
    let guid = sessionStorage.getItem("guid");
    let sessionUserId = Base64.decode(sessionStorage.getItem("UI"));
    await dispatch(
      getInspectionData({
        guid: guid,
        associate_company: sessionAssociateCompanyKey,
        user_id: sessionUserId,
      })
    );
  };

  useEffect(() => {
    if (assignColorList) {
      const preSelected = assignColorList
        .filter((color) =>
          assignColorIndicatorCategory?.inspector_flagging_guids?.includes(
            color.guid
          )
        )
        ?.map((color) => color.guid);
      setSelectedColors(preSelected);
    }
  }, [assignColorList]);

  const renderColorIndicators = () =>
    assignColorList
      ?.filter((color) => color?.is_deleted !== 1)
      ?.map((color) => (
        <Typography
          key={color.guid}
          className={`assign_color_bordered__text ${
            selectedColors.includes(color.guid) ? "assign_color_selected" : ""
          }`}
          onClick={() => toggleColorSelection(color.guid)}
          style={{
            cursor: "pointer",
            "&:hover": { backgroundColor: "transparent !important" },
          }}
        >
          <span
            className="bordered__text__content"
            style={{ display: "flex", alignItems: "center", gap: "8px" }}
          >
            <div
              style={{
                width: "18px",
                height: "18px",
                borderRadius: "50%",
                backgroundColor: color.color_code,
                border: "2px solid #FFFFFF",
              }}
            ></div>
            {color.name}
          </span>
          {selectedColors?.includes(color?.guid) && (
            <DoneIcon className="assign_color_selected_icon" />
          )}
        </Typography>
      ));

  return (
    <>
      <Dialog
        className="dialog-container-new-category"
        open={assignColorIndicatorModal}
        onClose={closeModal}
        aria-labelledby="category-modal"
        aria-describedby="category-modal-description"
      >
        <div className="close-wrapper-new-line">
          <CloseIcon onClick={closeModal} id="close_description_modal" />
        </div>
        <DialogTitle
          className="new-category-dialog-title"
          sx={{ marginBottom: "2px !important" }}
        >
          Color Indicator
        </DialogTitle>
        <span className="category-dialog-subtitle">
          Set who is responsible for what.
        </span>
        <DialogContent className="dialog-content-new-category">
          <Grid container className="input-controll">
            <Grid item xs={12}>
              {renderColorIndicators()}
              <Typography
                className="assign_color_bordered__text"
                id="add_new_des_button"
                onClick={() => setEditColorIndicator(true)}
              >
                <MdEdit style={{ marginRight: "8px", fontSize: "18px" }} />
                <span className="bordered__text__content">Edit list</span>
              </Typography>
            </Grid>
          </Grid>
        </DialogContent>
        <Divider />
        <DialogActions className="new-category-modal-action">
          <Button
            className="add-category-btn"
            id="save_description"
            onClick={assignColorsToCategory}
          >
            Done
          </Button>
        </DialogActions>
      </Dialog>
      {editColorIndicator && (
        <EditColorIndicator
          assignColorIndicatorModal={assignColorIndicatorModal}
          setAssignColorIndicatorModal={setAssignColorIndicatorModal}
          editColorIndicator={editColorIndicator}
          setEditColorIndicator={setEditColorIndicator}
          handleAssignColor={handleAssignColor}
          assignColorList={assignColorList}
          storeData={storeData}
          callInitiateAPI={callInitiateAPI}
          closeModal={closeModal}
        />
      )}
    </>
  );
};

export default AssignColorIndicatorModal;
